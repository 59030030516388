.Page {
  //Layout
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 40px 80px;
  width: 100vw;
  height: 100%;
  gap: 24px;
  overflow-y: auto;

  //Style
  background-color: rgb(235, 244, 247);
}

.PrintPage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  break-before: page;
  padding: 70px !important;
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  overflow: hidden;

  gap: 10.2px;
  align-content: flex-start;

  //Style
  background-color: #fff !important;
}

.SafariPrintPage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  break-before: page;
  padding: 70px !important;
  width: 210mm !important;
  height: 250mm !important;
  max-width: 210mm !important;
  max-height: 250mm !important;
  overflow: hidden;

  gap: 10.2px;
  align-content: flex-start;

  //Style
  background-color: #fff !important;
}

