.personBarGraphPairListListDefault {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 2rem;
    background-color: rgb(255, 255, 255);
    gap: 1.4375rem;
    width: 100%;

    .tableContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-top: 1rem;
    }

    .rowContainer {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        justify-content: space-between;
    }

    .rowLabel {
        display: flex;
        flex-direction: column;
        width: 6.25rem;
        justify-content: center;
        align-items: center;
    }

    .rowSubTitle {
        border-bottom: 1px dashed #707B8F;
        text-align: center;
    }

    .cell {
        flex: 1;
        display: flex;
        text-align: 'center';
        margin-left: 0.5rem;
    }

    .headerContainer {
        display: flex;
        justify-content: space-between;
        margin-left: 0.5rem;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .header {
        flex: 1;
        display: flex;
        text-align: 'center';
        margin-left: 0.5rem;
    }

    .personBarGraphPairList {
        display: flex;
        width: unset;
        align-self: stretch;
        width: 100%;
    }

}

.printPersonBarGraphPairListListDefault {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 13.83px;
    background-color: rgb(255, 255, 255);
    gap: 13.83px;
    width: 100%;

    .tableContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-top: 13.83px;
    }

    .rowContainer {
        display: flex;
        align-items: center;
        margin-bottom: 13.83px;
        justify-content: space-between;
    }

    .rowLabel {
        display: flex;
        flex-direction: column;
        width: 48px;
        justify-content: center;
        align-items: center;
    }

    .rowSubTitle {
        border-bottom: 1px dashed #707B8F;
        text-align: center;
    }

    .cell {
        flex: 1;
        display: flex;
        text-align: 'center';
        margin-left: 0.25rem;
    }

    .headerContainer {
        display: flex;
        justify-content: space-between;
        margin-left: 0.25rem;
        margin-top: 13.83px;
        margin-bottom: 13.83px;
    }

    .header {
        flex: 1;
        display: flex;
        text-align: 'center';
        margin-left: 0.25rem;
    }

    .personBarGraphPairList {
        display: flex;
        width: unset;
        align-self: stretch;
        width: 100%;
    }

}