.WorkforcegapTable {
  //Layout
  display: flex;
  flex-direction: column;
  border: 1px solid #E3E6EB;
  border-radius: 0.3rem;
  overflow: hidden;
  align-self: stretch;

  //Style
  .header {
    display: flex;
    flex-direction: row;
    background-color: #F4F5F8;
    height: 2.83rem; // as per design
  }

  .row {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-bottom: 1px solid #E3E6EB;
    flex: 1 1 auto;

    &:last-child {
      border: none;
    }

  }

  .cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    flex: 0 0 25%;
  }
}

.PrintWorkforcegapTable {
  //Layout
  display: flex;
  flex-direction: column;
  border: 0.576px solid #E3E6EB;
  border-radius: 2.75px;
  overflow: hidden;
  align-self: stretch;

  //Style
  .header {
    display: flex;
    flex-direction: row;
    background-color: #F4F5F8;
    height: 26.124px; // as per design
  }

  .row {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-bottom: 0.576px solid #E3E6EB;
    flex: 1 1 auto;

    &:last-child {
      border: none;
    }

  }

  .cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6.87px;
    flex: 0 0 25%;
  }
}