.EmptyStateContent {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 1.5rem;
  gap: 1.5rem;
  height: 100%;
  
  //Style
  background-color: #ffffff;
  border: 1px solid #e3e6eb;
  .icon {
    display: block;
    width: 5rem;
    height: 5rem;
  }

  .descriptionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 0.5rem;
  }

  .description {
    text-align: center;
  }

}

.EmptyStateContentHorizontal {
  height: 545px;
}