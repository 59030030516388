
p, span {

  &.Text {
    //Style
    color: #2F3846;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    //Font sizes
    &.xl{
      font-size: 32px;
    }
    &.lg {
      font-size: 18px;
    }
    &.md {
      font-size: 16px;
    }
    &.sm {
      font-size: 14px;
      letter-spacing: 0.21px;
    }
    &.xs {
      font-size: 12px;
      letter-spacing: 0.06px;
    }
  }

}

h1, h2, h3, h4, h5 {

  &.Text {
    //Font sizes
    &.xl{
      font-size: 40px;
    }
    &.lg {
      font-size: 32px;
    }
    &.md {
      font-size: 24px;
    }
    &.sm {
      font-size: 20px;
    }
    &.xs {
      font-size: 18px;
    }

    &.PrintText {

    }
  }
}


// base style common to all Text elements
.Text {

  font-family: Lato;

  //Font colors
  &.grey-2{
    color: #2F3846;
  }
  &.grey-4{
    color: #707B8F;
  }
  &.grey-8{
    color: #FFF;
  }
  &.royal-blue{
    color: #002664;
  }
  &.green{
    color:#0D8066;
  }
  &.purple{
    color: #7D5CCC;
  }
  &.primary {
    color: #0595ff;
  }
  &.error {
    color: #ff5343;
  }

  //Font weights
  &.regular {
    font-weight: 400;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
  &.no-wrap{
    white-space: nowrap;
  }

  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &.center {
    text-align: center;
  }

  //underline
  &.underline {
    text-decoration-line: underline;
  }
}

p, span {
  &.PrintText {
    color: #000;
    font-size: 7.65px;

    &.xl{
      font-size: 13.6px;
    }
    &.lg {
      font-size: 7.65px;
    }
    &.md {
      font-size: 6.8px;
    }
    &.sm {
      font-size: 5.95px;
      letter-spacing: 0.21px;
    }
    &.xs {
      font-size: 5.1px;
      letter-spacing: 0.026px;
    }
  }
}

h1, h2, h3, h4, h5 {
  &.PrintText {
    &.xl{
      font-size: 17px;
    }
    &.lg {
      font-size: 13.6px;
    }
    &.md {
      font-size: 10.2px;
    }
    &.sm {
      font-size: 8.5px;
    }
    &.xs {
      font-size: 7.65px;
    }
  }
}

.PrintText {
  font-family: Lato;
  //Font colors
  &.grey-2{
    color: #000;
  }
  &.grey-4{
    color: #000;
  }
  &.grey-8{
    color: #FFF;
  }
  &.royal-blue{
    color: #002664;
  }
  &.green{
    color:#0D8066;
  }
  &.purple{
    color: #7D5CCC;
  }
}