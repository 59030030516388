.InputField {
  //Layout
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 19.125rem;
  gap: 0.5rem;
 
  //Style
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(194, 199, 209);
  outline-offset: 0;

  // component styles start 
  .input {
    display: flex;
    width: unset;
    height: 1.125rem;
    flex: 1 1 auto;
    font-size: 0.875rem;
    font-family: 'Lato', 'sans-serif';
    color: rgb(112, 123, 143);

    &:disabled {
      color: rgb(47, 56, 70);
    }
  }

  .button {
    display: flex;
    margin-left: 0.5rem;
    padding: unset;
    background-color: unset;

    .icon {
      display: flex;
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .phoneIcon {
    display: block;
    width: 1.5rem;
    height: 1rem;
  }
  // component style end

  // variant style start
  &.text {
    padding: 0.75rem 1rem 0.75rem 1rem; 

    &:disabled {
      height: 2.625rem;
    }
  }

  &.password {
    padding: 0.6875rem 1rem 0.6875rem 1rem;
  }

  &.phone {
    padding: 0.75rem 1rem 0.75rem 1rem;
  }
  // variant style end

  &.error {
    outline: 1px solid rgb(255, 83, 67);
  }

  &.disabled {
    background-color: rgb(227, 230, 235);
  }

  &:focus-visible {
    outline: 1px dashed rgb(0, 66, 130);
  }

}