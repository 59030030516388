.CreateAccountPage {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  background-color: rgb(255, 255, 255);
  padding: 4rem 5rem 0rem 5rem;
  gap: 1.5rem;

  //Style
  .instructionContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .formContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: unset;
    align-self: stretch;
    gap: 1rem;

    .nameContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      align-self: stretch;
      gap: 1rem;

      .nameTextFields {
        display: flex;
        flex: 1 1 auto;
      }
    }
  }
}