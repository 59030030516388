.Glossary {
  //Layout
  display: flex;
  flex-direction: column;

  //Style

  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.2s ease-in-out;
  }
  
  .content.expanded {
    grid-template-rows: 1fr;
  }
  
  .contentWrapper {
    overflow: hidden;
  }

  .GlossaryTermList{
    display: flex;
    flex-wrap: wrap;
    gap: 16px; 
    margin-top: 40px;

    .termItem{
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 0 1 calc(25% - 12px); /* Each item takes up 1/4 minus gap */
    }
  }

}

