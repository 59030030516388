.ActionPage {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: unset;
  align-self: stretch;
  padding: 4rem 5rem 0rem 5rem;
  gap: 1.5rem;
    

  .instructionContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    gap: 0.25rem;
  }

  //Style
}