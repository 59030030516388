.ChartTooltip {
  //Layout
  display: flex;

  //Style
  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .Trend {
      display: flex;
      flex-direction: row;
      align-items: center;

      .arrow {
        svg {
          vertical-align: middle !important;
        }

        width: 16px;
        height: 16px;
      }

      &.rightAlignment {
        align-self: flex-end;
      }


    }

    .indicator {
      width: 12px;
      height: 12px;
      border-radius: 25px;
    }

    .seperator {
      width: 100%;
      height: 1px;
      background-color: #E3E6EB;
    }

    .leftPadding {
      padding-left: 16px;
    }

    &.StackedBarContent {
      gap: 8px;
      width: 250px;

      .dataPoint {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 6px;

        .dataPointLabel {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 6px;
        }

        .dataPointContent {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 6px;
        }

      }
    }

    &.StandardBarContent {
      .heading {
        display: flex;
        flex-direction: row;
        gap: 5px;
        flex-wrap: nowrap;
        align-items: center;
      }

    }

    &.StandardLayout {
      .title {
        display: flex;
        flex-direction: row;
        gap: 5px;
        flex-wrap: nowrap;
        align-items: center;
      }
    }
  }
}