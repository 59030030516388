.eICardContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;

  .totalEICard {
    display: flex;
    flex: 1 1 auto;
    width: calc(50% - 0.5rem);
  }
}

.printTableContainer {
  margin-top: 0 !important;
  outline: 0.421px solid #c2c7d1 !important;

  table {
    width: 100%;

    thead {
      background-color: #f4f5f8;

      tr {

        th {
          font-size: 6px !important;
          padding: 2px 4px !important;

          svg {
            visibility: hidden !important;
          }

          .table-headerItem {
            padding-bottom: 1px;
            border-bottom: 0.421px dashed gray;
          }
        }
      }
    }

    tbody {
      overflow-y: auto;

      tr {
        border: 0.421px solid #c2c7d1;
        padding: 2rem 0;
        border-left: 0;
        border-right: 0;

        td {
          font-size: 6px !important;
          padding: 4.25px 5.1px;
          border-right: 0.421px solid #c2c7d1;
        }

        td:first-child {
          padding-left: 5.1px;
          border-left: 0;
        }
      }
    }
  }
}

.maxHeightTableContainer {
  overflow-y: auto;
  max-height: 43.5rem;
}

.viewIndustryAverageButton {
  display: flex;
  align-self: flex-start;
  flex: 1 1 auto;

  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
}