.textInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;    
  }
  
  &:focus-visible {
    outline: 1px dashed rgb(0, 66, 130);  
  }
}