/**
* - Source Sans Pro (Required Weights)
*/
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}