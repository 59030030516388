.DashboardLayout {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  .header {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  //Style
}