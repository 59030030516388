.Tooltip {
  //Layout
  display: inline-flex;  
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  width: fit-content;
  padding: 8px 16px;

  //Style
  border-radius: 8px;
  box-shadow: 4px 2px 16px 0px rgba(52, 114, 140, 0.20);
  background: linear-gradient(white, white) padding-box,
              linear-gradient(to right,  #16417E,  #6ACAA4) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  z-index: 2;

  &.hidden {
    // display: none;
    pointer-events: none;
  }

}




