.Button {
  //Layout
  display: flex;
  padding: 4px 12px 4px 20px;
  align-items: center;
  gap: 4px;

  //Style
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
  cursor: pointer;

  // to remove underline from button
  text-decoration: none;

  &.secondary {
    color: #2F3846;
    border-radius: 100px;
    background: #E6F1F5;
  }

  &.dropdown {
    color: #2F3846;

    &:hover {
      background-color: rgb(244, 245, 248);
    }
  }

  &.outline {
    border-radius: 100px;
    border: 2px solid #296087;
  }

  &.primary {
    display: flex;
    justify-content: center;
    align-self: stretch;
    background-color: #0595ff;
    border-radius: 0.25rem;
    padding: 0.75rem 1rem;

    &:active {
      background-color: rgb(3, 89, 153);
      box-shadow: 0px 6px 16px rgba(52, 114, 140, 0.30000001192092896);
    
    }
    &:disabled {
      background-color: rgb(112, 123, 143);

      // text color for disabled button
      p {
        color: #c2c7d1;
      }
    
      &:hover {
        background-color: rgb(112, 123, 143);
        box-shadow: unset;
      }
    }
    &:focus-visible {
      background-color: rgb(3, 89, 153);
      outline: 1px dashed rgb(255, 255, 255);
      outline-offset: 0;
      box-shadow: 0px 6px 16px rgba(52, 114, 140, 0.30000001192092896);
    
    }
    &:hover {
      background-color: rgb(4, 119, 204);
      box-shadow: 0px 6px 16px rgba(52, 114, 140, 0.30000001192092896);
    
    }
  }

  &.default {
    display: flex;
    background-color: unset;
    gap: 0.5rem;
    padding: unset;

    &:focus-visible {
      outline: 1px dashed rgb(5, 149, 255);
      outline-offset: 0;
    }
  }

  &.subdued {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    border-radius: 100px;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;

    &:active {
      background-color: rgb(244, 245, 248);
    
    }
    &:focus-visible {
      background-color: rgb(244, 245, 248);
      outline: 1px dashed rgb(5, 149, 255);
      outline-offset: 0;
    
    }
    &:hover {
      background-color: rgb(244, 245, 248);
    
    }
  }

  &.profile-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f5f8;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 0.75rem;
  }
}