.ScreenSizeLayout {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  .header {
    display: flex;
    justify-content: center;
    height: 4rem;
  }

  .screenSizePage {
    display: flex;
    flex: 1 1 auto;
    align-self: stretch;
  }
}

// To hide for desktop breakpoint
@media (min-width: 1024px) {
  .ScreenSizeLayout {
    display: none;
  }
}