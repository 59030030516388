.SupplierInfo {
  //Layout
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
  align-self: stretch;

  //Style
  border-radius: 0.5rem;
  background: rgb(236, 244, 254);

  .labelContent {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    gap: 0.5rem;
  }

  .price {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
  }
}

.PrintSupplierInfo {
  padding: 3.37px;
  border-radius: 3.372px;

  .labelContent {
    gap: 3.37px;
  }
}