.KeyTakeaway {
  //Layout
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  //Style
  border-bottom: 1px solid #E3E6EB;
  padding-bottom: 0.75rem;
  .topContent {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .title {
    display: flex;
    align-self: center;
  }
}

.PrintKeyTakeaway {
  gap: 3.4px !important;
  padding-bottom: 5.1px !important;
}