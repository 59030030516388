.SignInPage {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  flex: 1 1 auto;
  align-self: stretch;
  padding: 4rem 5rem 0rem 5rem;

  //Style


  .instructionContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }

  .formContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
    gap: 1rem;

    .textField {
      display: flex;
      align-self: stretch;
    }

    .signInButton {
      display: flex;
      align-self: stretch;
    }

    .forgotPasswordButton {
      display: flex;
      align-self: flex-start;
      
      .icon {
        display: flex;
        padding: unset;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}