.LoaderModal {
  //Layout
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: none !important;
  background-color: unset !important;

  //Style
  .loader {
    display: block;
    height: 100px;
    width: 100px;
  }
}

.LoaderModalBackDrop {
  background: rgba(241, 242, 243, 0.8) !important;
  backdrop-filter: saturate(180%) blur(10px); // glass effect
}