.EmptyStateContent {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 8.5rem;
  gap: 1.5rem;
  height: 100%;
  
  //Style
  background-color: #ffffff;
  // .imageContainer {
  //   display: flex;
  //   align-self: stretch;
  // }

  .image {
    display: block;
    width: 19.125rem;
    height: 10.55275rem;
  }

  .descriptionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 1rem;
  }

  .description {
    text-align: center;
  }
}