.AuthenticationFooter {
  //Layout
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;

  //Style
  background-color: rgb(255, 255, 255);

  border-top: 1px solid rgb(227, 230, 235);
}