.TotalEICard {
  //Layout
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 1.5rem;

  //Style
  border-radius: 0.5rem;

  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;
    gap: 0.5rem;

    .button {
      display: flex;
      height: 1.125rem;
      width: 1.125rem;
      padding: 0;
      align-self: flex-start;
    }
  }

  .gradientText {
    h2 {
      background: -webkit-linear-gradient(0deg, #16417E 5%, #6ACAA4 40%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

}

.PrintTotalEICard {
  gap: 2px;
  padding: 0;
}