.ScreenSizeCard {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  gap: 1.5rem;

  .icon {
    display: flex;
    height: 4rem;
    width: 4rem;
  }

  .descriptionContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    gap: 0.5rem;
  }
}