.DropdownButton {
  //Layout
  display: flex;
  position: relative;

  .Button {
    display: flex;
  }

  .ListContainer {
    display: none;

    .ListItem {
      display: none;
    }
  }

  //Style

}

.DropdownButton:focus-within {
  .ListContainer {
    // Layout
    position: absolute;
    display: flex;
    flex-direction: column;
    top: calc(100% + 5px);
    left: 0;
    padding: 0.5rem;
    gap: 0.5rem;
    min-width: 120px;
    
    // Style
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    background-color: white;
    outline: 1px solid #c2c7d1;
    outline-offset: 0;

    .ListItem {
      display: flex;
    }
  }
}