.TextField {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.25rem;

  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .inputField {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .captionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    gap: 0.25rem;
  }

  .icon {
    display: flex;
    width: 1rem;
    height: 1rem;
    padding: unset;
  }

  //Style
}