.FilterModal {
  //Layout
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  height: 43.75rem;
  width: 50rem;
  padding: 1.5rem;
  position: fixed;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);

  z-index: 999;

  //Style
  border-radius: 16px;
  background-color: #fff;
}

.FilterModalBackdrop {
  //Layout
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 998;

  //Style
  background: #000c;
}

.FilterModalContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }

  .titleText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .closeIconButton {
    display: flex;
  }

  .filterColumnsContainer {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    width: unset;
    height: unset;
    flex: 1 1 auto;
    margin-top: 1.5rem;
    border-radius: 16px;
    outline: 1px solid rgb(194, 199, 209);
    outline-offset: 0;
  }

  .filterColumnLeftContainer {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: flex-start;
    overflow: hidden;
    align-self: stretch;
    height: auto;
    width: 100%;
    border-right: 1px solid rgb(194, 199, 209);
  }

  .filterColumnRightContainer {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: flex-start;
    overflow: hidden;
    align-self: stretch;
    height: auto;
    width: 100%;
  }

  .filterLeftContainer {
    align-items: flex-start;
    align-self: stretch;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .filterRightContainer {
    align-items: flex-start;
    align-self: stretch;
    background-color: #fff;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    overflow: hidden;
    padding: 1rem 0.5rem;
    width: auto;
  }

  .columnHeader {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;
    background-color: rgb(236, 245, 250);
  }

  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .closeButton {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    background-color: #fff;
  }

  .applyButton {
    display: flex;
    margin-left: 0.625rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .searchIcon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }

  .searchBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    outline: 1px solid rgb(194, 199, 209);
    outline-offset: 0;
    width: 100%;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
    cursor: pointer;
  }


  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1.875rem;
  }

  .content {
    max-height: 50%;
    width: 100%;
  }

  .list {
    gap: 0;
    border-top: 1px solid rgb(194, 199, 209);
    overflow: auto;
    width: 100%;
  }

  .suppliersList {
    flex-wrap: nowrap;
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }

  .filterRow {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
    width: 100%;
    min-height: 2rem;
  }

  .horizontalChipList {
    flex-direction: row;
    align-items: center;
    display: flex;
    margin-left: 0.25rem;
    text-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 0.25rem;
    justify-content: flex-start;
  }

  .chipButton {
    padding: 0.25rem 0.5rem;
    background-color: #e3e6eb;
    border-radius: 100px;
  }

  .chipIcon {
    display: block;
    height: 1rem;
    width: 1rem;
  }

  .emptyStateContainer {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1rem;
    padding: 2.5rem 0.625rem;
    width: auto;
  }

  .emptySearchIcon {
    height: 2.5rem;
    width: 2.5rem;
  }
}