.LargeHeadingCard {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .Header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;

    .headerTitle {
      display: flex;
      align-self: stretch;

      p {
        font-size: 2.5rem;
      }
    }

    .headerDescription {
      display: flex;
      align-self: stretch;
    }
  }
  //Style
}