.InitialContainer {
  //Layout
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.4375rem 0.5rem 0.4375rem 0.5rem;
  
  //Style
  border-radius: 100px;
  background-color: rgb(244, 245, 248);
}