.EthnicityGenderPeopleChart {
  //Layout
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem 0rem;

  //Style
  .chartContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.5rem 2rem;
    flex-wrap: wrap;

    .GenderChart {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      gap: 0.25rem;
      width: calc(33% - 24px);
      align-items: center;
  
      .genderSVG {
        display: flex;
        justify-content: center;
        gap: 0.25rem;
      }
  
      .text {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .PrintGenderChart {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      gap: 2.37px;;
      width: 58.3px;
      align-items: center;
  
      .genderSVG {
        display: flex;
        justify-content: center;
        gap: 2.37px;
      }
  
      .text {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .legendGroup {
    display: flex;
    justify-content: center;
  }
}