.Header {
  //Layout
  display: flex;
  flex-direction: column;
  align-self: stretch;

  //Style
  border-bottom: 1px solid rgb(227, 230, 235);

  .logoProfileContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0 0 auto;
    padding-left: 1.25rem;

    .Logo {
      display: block;
      background-color: rgb(255, 255, 255);
      width: 4.9041972160339355rem;
      height: 3.125rem;
    }

    .PrintAndProfileContent {
      display: flex;
      gap: 1rem;

      .printButton {
        display: flex;
        padding-right: 1.5rem;
        border-right: 1px solid rgb(194, 199, 209);
        gap: 0.5rem;
        align-self: center;

        &:hover {
          background-color: rgb(244, 245, 248);
        }
      }

      .accountMenuButton {
        display: flex;
        gap: 0.5rem;
        padding: 0.75rem 1rem;
      }

      .accountMenuItem {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        gap: 0.5rem;
        text-decoration: none;
        padding: 0.75rem;

        .icon {
          display: flex;
          flex: 0 0 auto;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  .filterNavContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0 0 auto;
    padding: 1.25rem;

    .navContent {
      display: flex;
      flex-direction: row;
    }

    .filterContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 1.25rem;

      .filterButton {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 100px;
        background-color: rgb(255, 255, 255);
        outline-offset: 0;
        gap: 0.5rem;

        &:hover {
          background-color: rgb(244, 245, 248);
          box-shadow: 0px 6px 16px rgba(52, 114, 140, 0.30000001192092896);
        }
      }

      .yearFilterButton {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 100px;
        background-color: rgb(255, 255, 255);
        outline-offset: 0;

        &:hover {
          background-color: rgb(244, 245, 248);
          box-shadow: 0px 6px 16px rgba(52, 114, 140, 0.30000001192092896);
        }

      }

      .yearFitlerItem {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        gap: 0.25rem;
        text-decoration: none;
        padding: 0.5rem 0.25rem;
      }

    }

  }
}