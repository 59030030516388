.iconArrowDownDataPurpleDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=arrowdownward], [id=arrowdownward] path {
    display: block;
    fill: rgb(125, 92, 204);
  }
  
}

.iconArrowDownPrimaryDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=arrowdownward], [id=arrowdownward] path {
    display: block;
    fill: rgb(5, 149, 255);
  }
  
}

.iconArrowLeftNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=arrowback], [id=arrowback] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconArrowLeftNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=arrowback], [id=arrowback] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconArrowUpPrimaryDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=arrowupward], [id=arrowupward] path {
    display: block;
    fill: rgb(5, 149, 255);
  }
  
}

.iconArrowUpYoYGreenDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=arrowupward], [id=arrowupward] path {
    display: block;
    fill: rgb(13, 128, 102);
  }
  
}

.iconCalculationsNeutralSubduedSelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Calculate], [id=Calculate] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconCheckNeutralDisabledDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Check], [id=Check] path {
    display: block;
    fill: rgb(194, 199, 209);
  }
  
}

.iconCheckboxEmptyNeutralDisabledSelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=checkboxoutlineblank], [id=checkboxoutlineblank] path {
    display: block;
    fill: rgb(194, 199, 209);
  }
  
}

.iconCheckboxFilledPrimarySelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=checkbox], [id=checkbox] path {
    display: block;
    fill: rgb(5, 149, 255);
  }
  
}

.iconChevronDownNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=expandmore], [id=expandmore] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconChevronUpNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=expandless], [id=expandless] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconCloseNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Close], [id=Close] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconCloseNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Close], [id=Close] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconColumnArrowPrimaryDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  .ellipse4 {
    display: block;
    background-color: rgb(217, 217, 217);
    width: 100%;
    height: 100%;
  }
  
  .ellipse5 {
    display: block;
    background-color: rgb(236, 245, 250);
    width: 100%;
    height: 100%;
  }
  
  path[id=arrowforwardios], [id=arrowforwardios] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconErrorCircleErrorDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Error], [id=Error] path {
    display: block;
    fill: rgb(255, 83, 67);
  }
  
}

.iconErrorOctagonErrorDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Report], [id=Report] path {
    display: block;
    fill: rgb(255, 83, 67);
  }
  
}

.iconExpandNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Fullscreen], [id=Fullscreen] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconExternalLinkNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  // MANUAL OVERRIDE
  path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconExternalLinkPrimaryDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=openinnew], [id=openinnew] path {
    display: block;
    fill: rgb(5, 149, 255);
  }
  
}

.iconEyeClosedNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=visibilityoff], [id=visibilityoff] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconEyeOpenNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Visibility], [id=Visibility] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconFilterNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=filterlist], [id=filterlist] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconFilterNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: rgb(112, 123, 143);
  }
}

.iconGraphNeutralSubduedSelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=barchart4bars], [id=barchart4bars] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconInfoNeutralSubduedDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Info], [id=Info] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconInfoTooltipSecondaryDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  .ellipse1 {
    display: block;
    background-color: rgb(230, 241, 245);
    width: 100%;
    height: 100%;
  }
  
  path[id=Info], [id=Info] path {
    display: block;
    fill: rgb(28, 27, 31);
  }
  
}

.iconKnowledgeNeutralDefaultSelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=School], [id=School] path {
    display: block;
    fill: rgb(28, 27, 31);
  }
  
}

.iconLogoutNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Logout], [id=Logout] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconLogoutNeutralSubduedSelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Logout], [id=Logout] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconPlusNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Add], [id=Add] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconPolicyNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Assignment], [id=Assignment] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconPolicyNeutralSubduedSelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Assignment], [id=Assignment] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconPrintNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Print], [id=Print] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconProfileNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=accountcircle], [id=accountcircle] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconProfileNeutralSubduedSelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=accountcircle], [id=accountcircle] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconQuestionPrimaryDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Help], [id=Help] path {
    display: block;
    fill: rgb(5, 149, 255);
  }
  
}

.iconResetPrimarySelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=restartalt], [id=restartalt] path {
    display: block;
    fill: rgb(5, 149, 255);
  }
  
}

.iconScreenSizePrimarySelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=aspectratio], [id=aspectratio] path {
    display: block;
    fill: rgb(28, 27, 31);
  }
  
}

.iconSearchNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Search], [id=Search] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconSearchNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Search], [id=Search] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconSortNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=unfoldmore], [id=unfoldmore] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}

.iconSortNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=unfoldmore], [id=unfoldmore] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconTermsNeutralSubduedDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Encrypted], [id=Encrypted] path {
    display: block;
    fill: rgb(112, 123, 143);
  }
  
}

.iconTermsNeutralSubduedSelected {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Encrypted], [id=Encrypted] path {
    display: block;
    fill: rgb(47, 56, 70);
  }
  
}
.iconUSFlagNeutralDefaultDefault {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}
