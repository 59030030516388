.KeyTakeawayNumber {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem 0.5rem;
  height: 1.5rem;

  //Style
  background-color: #002664;
  border-radius: 0.5rem;
}

.PrintKeyTakeawayNumber {
  padding: 0 3px !important;
  height: 10.2px !important;
  width: 10.2px !important;
}