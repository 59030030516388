.JobMapGraphCard {
  //Layout
  display: flex;
  flex-direction: row;
  height: 560px;

  //Style
  border-radius: 1rem;
  border: 1px solid #C2C7D1;

  .leftContent {
    display: flex;
    flex-direction: column;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .bottomLeftContent {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;

    gap: 2.6875rem;
  }

  .rightContent {
    display: flex;
    flex: 1 1 auto;
    max-width: 60%;
    flex-direction: column;

    background: #EFF9FF;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}

.PrintJobMapGraphCard {
  border-radius: 6.74px;
  .leftContent {
    display: flex;
    flex-direction: column;
    flex: 0 0 32.25%;
    max-width: 32.25%;
  }

  .bottomLeftContent {
    display: flex;
    flex-direction: column;
    padding: 10.2px;

    gap: 13.49px;
  }

  .rightContent {
    max-width: 67.75%;
    border-top-right-radius: 6.74px;
    border-bottom-right-radius: 6.74px;
  }
}