.tableContainer {
  width: 100%;
  outline: 1px solid #c2c7d1;
  border-radius: 4px;
  margin-top: 24px;

  table {
    width: 100%;

    thead {
      background-color: #f4f5f8;
      position: sticky;
      top: 0px;
      margin: 0 0 0 0;

      tr {

        th {
          color: #2f3846;
          font-family: Lato;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.21px;
          text-align: left;
          padding: 18px 4px 20px 8px;
          border-bottom: 0;

          div {
            display: flex;
            width: fit-content;
          }

          .tableHeaderItem {
            flex-direction: column;
            display: flex;
            padding-bottom: 1px;
            border-bottom: 1px dashed gray;
          }

          .cursorPointer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 5px;
          }

          .tableHeaderSort {
            display: flex;
            align-self: center;
            width: 16px;
            height: 24px;
          }

          .cursorPointer {
            cursor: pointer;
          }

        }
      }
    }

    tbody {

      tr {
        border: 1px solid #c2c7d1;
        padding: 2rem 0;
        border-left: 0;
        border-right: 0;

        td {
          padding: 7px 4px;
          text-align: left;
          border-right: 1px solid #c2c7d1;
          letter-spacing: 0.21px;
          text-align: right;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          color: #2f3846;

          .cellItem {
            text-align: left;
          }

          .cellItemBold {
            font-weight: 900;
          }
        }

        td:first-child {
          padding-left: 12px;
          border-left: 0;
        }
      }
    }
  }

  table tbody tr td:last-child {
    border-right: 0px;
  }

  table tbody tr:first-child {
    border-top: 0px;
  }

  table tbody tr:last-child {
    border-bottom: 0px;
  }
}

.maxHeightTableContainer {
  overflow-y: auto;
  max-height: 43.5rem;
}

th {
  border-bottom: 1px solid lightgray;
  padding: 2px 4px;

  .sortIcon {
    padding: 0 0 0 4px;
  }
}

.highlightValue {
  background-color: #bae2ff;
}