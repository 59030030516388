.checkboxItemExpandable {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .checkboxItemContainer {
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }

  .checkboxItem {
    align-items: flex-start;
    align-self: stretch;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    align-self: stretch;
  }

  .checkboxItemButton {
    align-self: stretch;
    background-color: #fff;
    padding: 0;
  }

  .checkbox {
    background-color: #fff;
    display: block;
    height: 1.25rem;
    width: 1.25rem;
    padding: 0;
  }

  .checkboxText {
    display: flex;
    margin-left: 0.5rem;
    padding: 0;
    width: 100%;
    text-wrap: wrap;
    text-align: left;
  }

  .checkboxIcon {
    background-color: #fff;
    padding: 0;
  }

  .content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.2s ease-in-out;
  }

  .content.expanded {
    grid-template-rows: 1fr;
  }

  .contentWrapper {
    overflow: hidden;
  }

  .subCheckboxLits {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    padding: 0 0 0 1.625rem;
  }

  .verticalDivider {
    background-color: #2f3846;
    width: 1px;
  }

  .list {
    gap: 0;
  }

  .checkboxIcon {
    height: 1.25rem;
    width: 1.25rem;
  }
}