.ErrorBanner {
  //Layout
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 229, 227);
  gap: 0.5rem;

  .icon {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    padding: unset;
  }

  //Style
}