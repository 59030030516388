.TotalAdjustmentCard {
  //Layout
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 1.5rem;

  //Style
  border-radius: 0.5rem;

  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;
    gap: 0.5rem;

    .button {
      display: flex;
      height: 1.125rem;
      width: 1.125rem;
      padding: 0;
      align-self: flex-start;
    }
  }
}

.PrintTotalAdjustmentCard {
  padding: 0;
  gap: 10px;
}