.ExpandableComponent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgb(194, 199, 209);
  
  .header{
    width: 100%;
  }

  .content {
    display: grid;
    width: 100%;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.2s ease-in-out;
  }

  .content.expanded {
    grid-template-rows: 1fr;
    width: 100%;
    overflow: hidden;
  }
}