.JobMapGraphCardLegends {
  //Layout
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 0.5rem;
  padding: 1rem;

  //Style
  .table {
    display: flex;
    align-self: center;

    border-collapse: collapse;
    border: 1px solid #707B8F;
  }

  .column {
    width: 3.13rem;
    height: 0.73rem;

    border-right: 1px solid white;
    // background-color: blue;
  }

  .columnOne {
    background-color: #F4F5F8;
  }
  .columnTwo {
    background-color: #D0E8F5;
  }
  .columnThree {
    background-color: #A1D0EB;
  }
  .columnFour {
    background-color: #5BADDB;
  }
  .columnFive {
    background-color: #148BCC;
  }
  .columnSix {
    background-color: #004282;
    border-right: none;
  }
}

.PrintJobMapGraphCardLegends {
  gap: 3px;
  padding: 10.12px;

  .column {
    width: 30.683px;
    height: 4.986px;
  }
}