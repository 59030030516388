.WorkforceGapGenderEthnicityCard {
  //Layout
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;

  //Style

  .chart {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #E3E6EB;
    flex: 0 0 calc(50% - 24px);
    align-self: stretch;
  }

  .table {
    display: flex;
    flex: 0 0 50%;
  }
}

.CompanyState, .PortfolioState {
  padding: 2.5rem 8.5rem;
}