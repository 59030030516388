.ProfilePage {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ebf4f7;
  padding: 2.5rem;
  gap: 1.5rem;

  .backToDashboardButton {
    display: flex;
    gap: 0.5rem;
  }

  .formContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
  }

  .profileCard {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-self: stretch;
    gap: 1.5rem;

    .form {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .textFieldGroup {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        gap: 16px;
  
        .textField {
          display: flex;
          flex: 1 1 50%;
          gap: 0.5rem;
        }
      }

      .submitButton {
        display: flex;
        align-self: flex-start;
        padding: 0.75rem 1.5rem;
      }
    }
  }

  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
    flex: 0 0 19.9375rem;
    align-self: stretch;

    .needHelpCard {
      display: flex;
      justify-content: center;
      flex: 1 1 auto;
      gap: 0.5rem;
      align-self: stretch;
    }

    .usefulLinksCard {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .usefulLinkButtons {
        //Layout
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        align-self: stretch;
        overflow: hidden;
        border-radius: 0.5rem;
        background-color: #f4f5f8;
      
        .linkButton {
          display: flex;
          border-radius: unset;
      
          &:not(:first-child) {
            border-top: 1px solid #c2c7d1;
          }
        }
      }
    }
  }

  //Style
}