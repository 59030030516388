.SkeletonRectangle {
  //Layout
  display: flex;
  flex: 1 1 auto;
  height: 1rem;

  //Style
  border-radius: 0.25rem;
  background: #E3E6EB;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}