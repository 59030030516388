.JobMapGraphCardSupplierList {
  //Layout
  display: flex;
  flex-direction: column;
  gap: 1rem;

  //Style
  .supplierContentTitle {
    display: flex;
    flex-direction: row;
  }

  .supplierList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    height: 242.5px; // static height to add scroll for more than 6 items in the list
    overflow-y: auto;
  }

  .supplierInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;

    border-radius: 0.5rem;
    background: #ECF4FE;
  }
}

.PrintJobMapGraphCardSupplierList {
  gap: 6.74px;

  .supplierList {
    gap: 4.21px;
  }
}