.ScreenSizePage {
  //Layout
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  background-color: rgb(235, 244, 247);

  .screenSizeCard {
    display: flex;
    align-self: stretch;
  }
  //Style
}