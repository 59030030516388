.AuthenticationLayout {
  //Layout
  display: flex;
  flex-direction: column;
  height: 100%;

  //Style

  .Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 4rem;
  }

  .PageContent {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 4rem); // full height - header height
    overflow-y: auto;

    .Image {
      display: flex;
      flex: 1 0 50%;
      align-self: stretch;
      height: auto;
    }

    .FormContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      flex: 1 0 50%;
      align-self: stretch;

      .Form {
        display: flex;
        width: unset;
        height: unset;
        align-self: stretch;
        flex: 1 1 auto;
      }

      .Footer {
        display: flex;
        align-self: stretch;
        margin-top: 7.0625rem;
      }
    }
  }
}