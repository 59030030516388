.TooltipIcon {
  //Layout
  display: inline-flex;
  position: relative;
  cursor: pointer;

  //Style
  .Tooltip{
    display: flex;
    flex-direction: column;
    gap:8px;
    width:max-content;
    max-width: 350px;
    .HeaderTooltip{
      display: flex;
      flex-direction: column;
      gap:8px;
    }
    
  }

}