.Card {
  //Layout
  display: flex;
  flex-direction: column;
  padding: 24px;
  

  //Style
  border-radius: 16px;
  border: 1px solid #C2C7D1;
  background: #FFF;
  .chart{
    height: 670px;
  }
}

.PrintCard {
  padding: 10px;
  gap: 10px;
  border-radius: 6px;
  border: 0.421px solid #C2C7D1;
}