.JobMapGraphCardHeader {
  //Layout
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;

  //Style
  border-bottom: 1px solid #C2C7D1;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .label {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
  }

  .value {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
  }
}

.PrintJobMapGraphCardHeader {
  padding: 10px;
  gap: 3.37px;
}