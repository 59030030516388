@import './resources/scss/fonts.scss';

// Import reset.css to override some of the bootstrap global settings
@import './reset.css';

.layout {
  height: 100%;
}

// To hide for mobile breakpoint
@media (max-width: 1023px) {
  .layout {
    display: none;
  }
}