.JobMapGraphCardRateContent {
  //Layout
  display: flex;
  flex-direction: column;
  gap: 1rem;

  //Style
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .label {
    display: flex;
    flex-direction: row;
    flex: 0 0 60%;
  }

  .value {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
  }
}

.PrintJobMapGraphCardRateContent {
  gap: 6.74px;
}