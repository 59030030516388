.jobCreationPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  background-color: rgb(235, 244, 247);
  width: 64rem;

  .pageContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .dimensionHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .middleContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: unset;
    align-self: stretch;
  }
  
  .totalAdjustmentCard {
    display: flex;
    width: 19.5rem;
    height: 8.5rem;
  }
  
  .takeawaysCard {
    display: flex;
    width: 19.5rem;
    height: unset;
    flex: 1 1 auto;
    margin-top: 1.5rem;
  }
  
  .jCAdjustGraphCard {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1.5rem;
  }
  
  .jobMapGraphCard {
    display: flex;
    width: unset;
    height: 40.875rem;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 2.5rem 5rem 2.5rem 5rem;
    width: unset;

  }
  @media (min-width: 1920px) {
    width: unset;

  }
}
