.ChartCard {
  //Layout
  display: flex;
  flex-direction: column;
  row-gap: 17px;
  align-self: stretch;


  //Style

  &.fill {
    width: calc(100%);
    // flex-grow: 1;
  }

  &.half {
    width: calc(50% - 12px);
  }

  &.offset {
    width: calc(100% - 312px - 24px); //width of takeway + gap
  }


}
.modalContent {
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: white;
  padding: 15px 27px;
}
.Header {
  display: flex;
  flex-direction: column;
  row-gap: 10.5px;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .leftside {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      column-gap: 8px;

      .TooltipIcon {
        margin-left: 8px;
      }
    }

    .button {
      display: flex;
      flex-direction: row; 
      align-self: start;
      align-items: center;
      column-gap: 8px;
      padding:8px 15px;
      svg{
        height:14px;
        width:14px;
      }
    }
  }

  .PrintHeader {
    row-gap: 4.5px !important;
  }
  .subtitle {}
}

.PrintChartCard {
  &.offset{
    width:calc(100% - 132px - 10.2px);//width of takeway + gap
  }
}

.LegendGroup {
  display: flex;
  flex-direction: row;
  column-gap: 13px;
  margin-top: auto;
  flex-wrap: wrap;

  .Legend{
    display: flex;
    flex-direction: row;
    column-gap: 6px;
    align-items: center;
    cursor: pointer;

    .indicator{
       //Layout
       display: block;
       width: 12px;
       height: 12px;
       background-color: red;
       cursor: pointer;

       //Style
       border-radius: 25px;
    }

    .label{
      border-bottom: 1px dashed #707B8F;
    }
  }

  .LegendTooltip{
    display: flex;
    flex-direction: column;
    gap:8px;
    .desc{
      width:max-content;
      max-width: 300px;
    }
  }
}

.PrintLegendGroup {
  .Legend {
    column-gap: 2.55px;
    .indicator {
      width: 5.1px;
      height: 5.1px;
    }
  }
}
