.NavItem {
  //Layout
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  align-self: center;

  //Style
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  border-radius: 100px;
  
  &:global(.active) {
    background-color: rgb(0, 38, 100);
    p{
      color: #FFF;
      font-weight: 600;
    }
   
  }

}