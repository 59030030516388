.Chart {
  //Layout
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: calc(100vh - 100px);
  width: 100%;

  //Style
  canvas {
    max-width: 100%;
  }

  .ChartWrapper {
    min-height: 100%;
    width: 100%;
    position: relative;
  }
}
.expanded {
  overflow-y: auto;
}