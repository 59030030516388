.KeyTakeawaysCard {
  //Layout
  display: flex;
  flex-direction: column;
  flex: 1 0 312px;
  gap: 1.5rem;
  height: 100%;

  //Style
  .KeyTakeawaysContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
  }
}

.PrintKeyTakeawaysCard {
  gap: 9.8px !important;
  flex: 1 0 132px !important;
  .PrintKeyTakeawaysContainer {
    gap: 6.4px !important;
  }
}