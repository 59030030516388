.ForgotPasswordPage {
  //Layout
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  height: 100%;
  padding: 4rem 5rem 0rem 5rem;
  gap: 1.5rem;
  flex: 1 1 auto;

  .instructionContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    gap: 0.25rem;
  }

  .formContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: unset;
    align-self: stretch;
    gap: 1rem;
  }

  //Style
}