.bottomText {
  color: var(--Yale-Blue, #004282);
  font-size: 8.936px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.251px;
}

.fullScreenCard {
  width: 100%;
  height: 100%;
  gap: 16px;
}

.notesAreaBox {
  width: 100%;
  height: 100%;
  border: 2px solid #C2C7D1;
}

.endPage {
  flex-direction: column;
  background-color: #fff !important;
  justify-content: flex-end;

  .bottomContainer {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .allDetailsContainer {
    display: flex;
  }

  .addressContainer {
    display: flex;
    min-width: 25%;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 48px;
    border-left: 2px solid #C2C7D1;
    border-bottom: 2px solid #C2C7D1;
  }

  .contactContainer {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-bottom: 48px;
    border-left: 2px solid #C2C7D1;
    border-bottom: 2px solid #C2C7D1;
    width: 100%;
  }
}

.TitlePage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  overflow: hidden;

  //Style
  background-color: #fff !important;
}

.SafariTitlePage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 210mm !important;
  height: 250mm !important;
  max-width: 210mm !important;
  max-height: 250mm !important;
  overflow: hidden;

  //Style
  background-color: #fff !important;
}