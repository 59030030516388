.SupplierCount {
  //Layout
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.1875rem 0.5rem 0.1875rem 0.5rem;
  border-radius: 100px;
  background: linear-gradient(1.5708rad, rgba(41, 96, 135, 1) 0%, rgba(106, 202, 164, 1) 100%);

  //Style
}