.toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 100px;
  background-color: rgb(244, 244, 244);
  gap: 10px;

  .toggleItems {
    display: flex;
    padding: 8px 20px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 100px;
    background: #F4F4F4;
  }

  .toggleItemSelected {
    background: var(--Royal-Blue-Dark, #002664);
  }

}