.GlossaryHeader {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
  display: flex;
  padding: 2rem;
  align-items: flex-start;
  align-self: stretch;

  .OverviewPageHeader {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.PageHeaderContainer {

  .PageHeaderRowContainer {
    display: flex;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .PageHeaderLeftContainer {
    flex: 1 ;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 50%;
    display: flex;
    gap: 1rem;
    align-items: flex-start;    
    align-self: stretch;
    justify-content: center;
  }

  .PageHeaderRightContainer {
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 50%;
    display: flex;
    gap: 1rem;
    border-left: 2px solid var(--Gradient, #296087);
    padding-left: 1rem;
  }

  .PageHeaderRightImageContainer {
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 50%;
    display: flex;
    gap: 1rem;
    align-items: flex-end;
  }

  .PageHeaderDivider {
    width: 100%;
    height: 1px;
    background: var(--Grey-Grey-6, #E3E6EB);
  }
}